/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-10-17 20:26:01
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-18 08:06:33
 * @FilePath: /site/src/components/imageCroper/index.js
 */
import ImageCropper from "./ImageCropper.vue";

ImageCropper.install = function (Vue) {
  Vue.component(ImageCropper.name, ImageCropper);
};

export default ImageCropper;
